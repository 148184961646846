/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://www.jobsineducation.net/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://www.jobsineducation.net/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://www.jobsineducation.net/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://www.jobsineducation.net/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://www.jobsineducation.net/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://www.jobsineducation.net/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://www.jobsineducation.net/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.44%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_008c0d {font-family: 'Inter', 'Inter Fallback';font-style: normal
}

body {
  color: #1c1b1b;
}

@font-face {
  font-family: "icomoon";
  src: url("/moon-icon/icomoon.woff?hqm31f") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

a {
  color: #2a5798;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\e909";
}
.icon-minus:before {
  content: "\e911";
}
.icon-add:before {
  content: "\e901";
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-contact-us:before {
  content: "\e903";
}
.icon-hidden:before {
  content: "\e904";
}
.icon-eye:before {
  content: "\e905";
}
.icon-museum:before {
  content: "\e906";
}
.icon-bookmark:before {
  content: "\e907";
}
.icon-verified:before {
  content: "\e908";
}
.icon-time:before {
  content: "\e90a";
}
.icon-graduation:before {
  content: "\e90b";
}
.icon-date:before {
  content: "\e90c";
}
.icon-chair:before {
  content: "\e90d";
}
.icon-rupee:before {
  content: "\e90e";
}
.icon-point:before {
  content: "\e90f";
}
.icon-user-add:before {
  content: "\e910";
}
.icon-user:before {
  content: "\e923";
}
.icon-search:before {
  content: "\e912";
}
.icon-checked:before {
  content: "\e913";
}
.icon-announcement:before {
  content: "\e914";
}
.icon-briefcase:before {
  content: "\e915";
}
.icon-office-building:before {
  content: "\e916";
}
.icon-down:before {
  content: "\e917";
}
.icon-sliders:before {
  content: "\e918";
}
.icon-close:before {
  content: "\e919";
}
.icon-filter:before {
  content: "\e91a";
}
.icon-send:before {
  content: "\e91b";
}
.icon-whatsapp:before {
  content: "\e91c";
}
.icon-online-course:before {
  content: "\e91d";
}
.icon-back:before {
  content: "\e91e";
}
.icon-multiple-users:before {
  content: "\e91f";
}
.icon-global:before {
  content: "\e920";
}
.icon-play-button:before {
  content: "\e921";
}
.icon-next:before {
  content: "\e922";
}
.icon-info:before {
  content: "\e935";
}
.icon-edit:before {
  content: "\e924";
}
.icon-delete:before {
  content: "\e925";
}
.icon-new-search:before {
  content: "\e926";
}
.icon-menu:before {
  content: "\e927";
}
.icon-notification:before {
  content: "\e928";
}
.icon-calender:before {
  content: "\e929";
}
.icon-open-mail:before {
  content: "\e92a";
}
.icon-location:before {
  content: "\e92b";
}
.icon-roundup:before {
  content: "\e92c";
}
.icon-coins:before {
  content: "\e92d";
}
.icon-resume:before {
  content: "\e92e";
}
.icon-bags:before {
  content: "\e92f";
}
.icon-backward:before {
  content: "\e930";
}
.icon-forward:before {
  content: "\e931";
}
.icon-approved:before {
  content: "\e932";
}
.icon-resume-upload:before {
  content: "\e933";
}
.icon-reset:before {
  content: "\e934";
}
.icon-quotations:before {
  content: "\e944";
}
.icon-thumbs-up:before {
  content: "\e936";
}
.icon-shild-outline:before {
  content: "\e937";
}
.icon-verify-outline:before {
  content: "\e938";
}
.icon-whatsappfill:before {
  content: "\e93c";
}
.icon-features:before {
  content: "\e93b";
}
.icon-users:before {
  content: "\e93a";
}
.icon-youtube:before {
  content: "\e939";
}
.icon-ticks:before {
  content: "\e93d";
}
.icon-twitter:before {
  content: "\e93e";
}
.icon-instagram:before {
  content: "\e93f";
}
.icon-linkedine:before {
  content: "\e940";
}
.icon-facebook:before {
  content: "\e941";
}
.icon-usernew:before {
  content: "\e943";
}
.icon-gender:before {
  content: "\e942";
}
.icon-settings:before {
  content: "\e946";
}
.icon-voice:before {
  content: "\e947";
}
.icon-ai:before {
  content: "\e948";
}
.icon-user-group:before {
  content: "\e945";
}
.icon-links:before {
  content: "\e900";
}


.infinite-scroll-component {
  padding-bottom: 2rem;
  overflow-x: hidden !important;
}

button:focus {
  outline: none !important;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.customLoader {
  border: 2px solid #fff;
  border-radius: 50%;
  border-top: 2px solid #2a5798;
  width: 16px;
  height: 16px; /* Safari */
  animation: spin 1s linear infinite;
  margin-right: 6px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overflowhidden{
  overflow: hidden;
}

.selectMenu .MuiPaper-root {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d9d9da;
  border-radius: 8px;
  max-height: 350px;
}
.selectMenu .MuiMenuItem-root {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.selectMenu .MuiPaper-root .Mui-selected {
  background-color: #e7f1fd;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otpInputField :focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.loginAndRegistration .MuiButton-contained.Mui-disabled {
  background-color: #2a5798;
  opacity: 0.6;
  color: #fff;
}
.loginAndRegistration .MuiButton-outlined.Mui-disabled {
  border-color: #2a5798;
  opacity: 0.7;
  color: #2a5798;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.suggestions-scrollbar {
  position: absolute;
  max-height: 300px;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 8px 29px #34353633;
  border-radius: 8px;
  z-index: 99;
  width: calc(100% - 32px);
}

.suggestions-scrollbar .suggestion-list {
  padding: 10px 16px;
}

.suggestions-scrollbar .suggestion-list:hover {
  background-color: #e7f1fd;
}

.signUpSignInTab .logRegBtn{
  border: 1px solid #2a5798;
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.signUpSignInTab .logRegBtn.contained{
  background-color: #2a5798;
  color: #fff;
}

.signUpSignInTab .logRegBtn.outlined{
  background-color: transparent;
  color: #2a5798;
}

@media (max-width: 767.98px) {
  .loginAndRegistration .MuiDialog-paper {
    background-color: #fafafa;
  }
  .loginAndRegistration form,
  .loginAndRegistration .innerForm {
    padding: 0 20px;
  }
  .loginAndRegistration .MuiDialogContent-root {
    padding: 0;
  }
  .signUpSignInTab {
    -moz-column-gap: 0px !important;
         column-gap: 0px !important;
  }
  .signUpSignInTab .logRegBtn {
    border: 0;
    font-size: 16px;
    padding: 16px 0;
    color: #555;
    border-radius: 0;
    box-shadow: none;
    background-color: #fff !important;
    opacity: 1;
    box-shadow: none;
  }
  .signUpSignInTab .logRegBtn.contained {
    color: #2a5798;
    border-bottom: 3px solid #2a5798;
  }
  .signUpSignInTab .logRegBtn.outlined {
    color: #555;
    border-bottom: 3px solid #fff;
  }
  .MuiFormHelperText-root {
    margin-right: 8px;
    margin-left: 8px;
  }
}

/* Styles for the backdrop */
.Backdrop {
  /* Background color */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value for transparency */

  /* Positioning */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Additional styles */
  z-index: 1300; /* Ensuring it's above other elements */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Animation */
  transition: opacity 0.3s ease;
}

/* Styles for the backdrop when it's hidden */
.MuiBackdrop-invisible {
  opacity: 0;
}

.contained-button {
  background-color: #2a5798;
  font-size: 14px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
}

.contained-button-info {
  background-color: #d1e5ff;
  font-size: 14px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
}

.outlined-button {
  border: 1px solid #2a5798;
  font-size: 14px;
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  color: #2a5798;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
}
.contained-button i, .contained-button-info i, .outlined-button i{
  position: relative;
  top: 1px;
  margin-right: 4px;
}
